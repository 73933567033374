<template>
<div>
    <v-container mt-10 mb-10></v-container>
    <v-container fluid class="pa-3 backgroundFooter">
        <v-layout>
            <v-flex xs12 md10 class="mx-auto pt-3 pb-3">
                <v-layout wrap>                    
                    <v-flex xs4 md3 class="align-self-center pa-2">
                        <v-img max-width="80" class="mx-auto" src="@/assets/img/footer/home/promass.png" />
                    </v-flex>
                    <v-flex xs4 md3 class="align-self-center pa-2">
                        <v-img max-width="80" class="mx-auto" src="@/assets/img/footer/home/club.png" />
                    </v-flex>
                    <v-flex xs4 md3 class="align-self-center pa-2">
                        <v-img max-width="90" class="mx-auto" src="@/assets/img/footer/home/hb.png" />
                    </v-flex>
                    <v-flex xs12 md3 class="align-self-center pa-2">
                        <v-img max-width="120" class="mx-auto" src="@/assets/img/footer/home/chedraui.png" />
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
    </v-container>
</div>
</template>

<script>
export default {
    name: "Footer"
}
</script>
