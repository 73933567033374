<template>
<div>
    <v-container fluid class="pa-0">
        <v-img :src="require('@/assets/img/'+banner+'.png')" />
    </v-container>
    <v-container class="mt-8">
        <p class="naranja font-weight-bold font-24 text-center">
            Bienvenid@
        </p>
        <p class="azul font-19 text-center">
            Protección Chedraui tiene como objetivo darte una protección integral, respaldándote<br>
            ante imprevistos en todo momento y dándote la tranquilidad que mereces.
        </p>
        <p class="azul font-weight-bold font-19 mt-3 text-center">
            Para ello, ponemos a tu disposición productos pensados<br>
            especialmente para ti:
        </p>
        <v-layout mt-10 wrap>
            <v-flex xs12 md6>
                <CardServicios :class="mProducto" img="ProteccionDespensa.png" logo="LogoDespensa.png" producto="despensa" />
            </v-flex>
            <v-flex xs12 md6 :class="mProducto">
                <CardServicios img="ProteccionAssits.png" logo="Assits.png" producto="assist" />                
            </v-flex>
            <v-flex xs12 md12 :class="mProducto">
                <CardServicios img="ProteccionRemesa.png" logo="ProteccionRemesa.png" producto="remesa" />
            </v-flex>
        </v-layout>
        <v-container mt-8 mb-8></v-container>
        <p class="azul text-center line-height">
            <span class="font-19">Para más información llámanos al:</span>
            <br>
            <span class="font-weight-bold font-25"><a class="noList naranja" href="tel:5554816021">55 54 81 60 21</a></span>
            <br>
            <span class="font-12">Horario de atención: las 24 horas de los 365 días del año</span>
        </p>
    </v-container>
    <Footer />
</div>
</template>

<script>
import CardServicios from "@/components/CardHome.vue";
import Footer from "@/components/Footer.vue";

export default {
    name: 'Home',
    components: {
        CardServicios,
        Footer
    },
    computed: {
        mProducto() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return 'mt-10';
                case 'sm':
                    return 'mt-10';
                case 'md':
                    return 'mt-10';
                case 'lg':
                    return 'mt-10';
                case 'xl':
                    return 'mt-10';
            }
        },
        banner() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return 'BannerMovil';
                case 'sm':
                    return 'BannerMovil';
                case 'md':
                    return 'Banner';
                case 'lg':
                    return 'Banner';
                case 'xl':
                    return 'Banner';
            }
        }
    }
}
</script>
