<template>
<div>
    <v-layout wrap>
        <v-flex xs12 md12>
            <v-img max-width="200" class="mx-auto" :src="require('@/assets/img/home/' + img)" />
        </v-flex>
        <v-flex xs12 md12 mt-5>
            <v-img max-width="200" class="mx-auto" :src="require('@/assets/img/logos/' + logo)" />
        </v-flex>
    </v-layout>
    <v-layout wrap v-if="producto == 'despensa'">
        <v-flex xs12 md12 mt-5 class="text-center font-18">
            <p class="azul text-center">
                Me da asistencia para:
            </p>
        </v-flex>
        <v-flex xs6 md4>
            <v-img max-width="25" class="mx-auto" :src="require('@/assets/img/despensa/MiBienestar.png')" />
            <p class="naranja text-center">
                Mi Bienestar
            </p>
        </v-flex>
        <v-flex xs6 md4>
            <v-img max-width="25" class="mx-auto" :src="require('@/assets/img/despensa/MiAsistencia.png')" />
            <p class="naranja text-center">
                Mi Asistencia
            </p>
        </v-flex>
        <v-flex xs6 md4>
            <v-img max-width="25" class="mx-auto" :src="require('@/assets/img/despensa/Mihogar.png')" />
            <p class="naranja text-center">
                Mi Hogar
            </p>
        </v-flex>
        <v-flex xs6 md6>
            <v-img max-width="25" class="mx-auto" :src="require('@/assets/img/despensa/MiCamino.png')" />
            <p class="naranja text-center">
                Mi Camino
            </p>
        </v-flex>
        <v-flex xs12 md6>
            <v-img max-width="25" class="mx-auto" :src="require('@/assets/img/despensa/MiSeguro.png')" />
            <p class="naranja text-center">
                Mi Seguro<br>
                <span class="azul font-12 font-weight-bold">pago de $10,000.000 pesos</span>
            </p>
        </v-flex>
        <v-flex xs12 md12>
            <p class="naranja text-center">
                17 asistencias y un Seguro por <span class="azul font-weight-bold">$23</span> al mes
            </p>
        </v-flex>
        <v-flex xs12 md12 class="text-center">
            <v-btn color="#0c55a6" class="btnNoMayuscula" dark small to="/despensa">Quiero conocer la información</v-btn>
        </v-flex>
        <v-flex xs12 md6 mt-5 class="text-center">
            <v-btn color="#0c55a6" class="btnNoMayuscula" dark small to="/clientedespensa">Ya soy cliente</v-btn>
        </v-flex>
        <v-flex xs12 md6 mt-5 class="text-center">
            <v-btn color="#0c55a6" class="btnNoMayuscula" dark small to="/contratadespensa">Quiero contratarlo</v-btn>
        </v-flex>
    </v-layout>
    <v-layout wrap v-if="producto == 'remesa'">
        <v-flex xs12 md12 mt-5 class="text-center" v-if="producto == 'remesa'">
            <p class="naranja font-18 text-center mt-5 font-weight-bold">
                Al cobrar tu Remesa en Chedraui, tu dinero y quien te lo mande ¡Están protegidos!
            </p>
            <ul>
                <li class="azul font-weight-bold font-15">Robo de efectivo con violencia por retiro de remesa</li>
                <li class="azul font-15 mt-3"><span class="font-weight-bold">Línea legal:</span> Orientación telefónica sobre lo que debes hacer en caso de robo con violencia.</li>
                <li class="azul font-15 mt-3"><span class="font-weight-bold">Repatriación:</span> En caso de fallecimiento del familiar que hace el envio de la remesa.</li>
            </ul>
            <br>
            <v-btn color="#0c55a6" class="btnNoMayuscula" dark small to="/remesa">Quiero conocer la información</v-btn>
        </v-flex>
    </v-layout>
    <v-layout wrap v-if="producto == 'assist'">
        <v-flex xs12 md12 mt-5 class="text-center font-18">
            <p class="azul text-center">
                Me da proteccion familiar
            </p>
        </v-flex>
        <v-flex xs12 md12>
            <v-img max-width="25" class="mx-auto" :src="require('@/assets/img/assists/phone.png')" />
            <p class="naranja text-center">
                Mi linea de ayuda
            </p>
        </v-flex>
        <v-flex xs12 md6 class="mx-auto">
            <v-layout wrap>
                <v-flex xs6 md7>
                    <ul>
                        <li class="azul font-15">Línea legal</li>
                        <li class="azul font-15">Línea escolar</li>
                        <li class="azul font-15">Enlace para emergencia</li>
                    </ul>
                </v-flex>
                <v-flex xs6 md4>
                    <ul>
                        <li class="azul font-15">Electricista</li>
                        <li class="azul font-15">Cerrajero</li>
                        <li class="azul font-15">Vidriero</li>
                        <li class="azul font-15">Plomero</li>
                    </ul>
                </v-flex>
            </v-layout>
        </v-flex>
        <v-flex xs12 md12>
            <p class="naranja text-center mt-4">
                7 asistencias por <span class="azul font-weight-bold">$2</span> pesos al mes
            </p>
        </v-flex>
        <v-flex xs12 md12 class="text-center">
            <v-btn color="#0c55a6" class="btnNoMayuscula" dark small to="/assists">Quiero conocer la información</v-btn>
        </v-flex>
        <v-flex xs12 md12 mt-5 class="text-center">
            <v-btn color="#0c55a6" class="btnNoMayuscula" dark small to="/clienteassit">Ya soy cliente</v-btn>
        </v-flex>
    </v-layout>
</div>
</template>

<script>
export default {
    name: "CardHome",
    props: ["img", "logo", "textoBtn", "producto"]
}
</script>

<style>
ul {
    list-style: none;
    /* Quitamos los marcadores */
    padding: 0;
    /* Quitamos el padding por defecto de la lista */
    margin-left: 10px;
    /* Separamos la lista de la izquierda */
}

li::before {
    /* Añadimos contenido antes de cada elemento de la lista */
    content: "\2022";
    /* Insertamos el marcador */
    padding-right: 8px;
    /* Establecemos el espacio entre las viñetas y el list item */
    color: red;
    /* Coloreamos la viñeta */
}
</style>
